import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section, Link, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdStars } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Про гольф-клуб G-Stream
			</title>
			<meta name={"description"} content={"У гольф-клубі G-Stream наша історія не просто про гольф - вона про створення притулку, де живе і дихає дух гри. "} />
			<meta property={"og:title"} content={"Про нас | Про гольф-клуб G-Stream"} />
			<meta property={"og:description"} content={"У гольф-клубі G-Stream наша історія не просто про гольф - вона про створення притулку, де живе і дихає дух гри. "} />
			<meta property={"og:image"} content={"https://wilonfisplad.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wilonfisplad.com/img/5147640.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wilonfisplad.com/img/5147640.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wilonfisplad.com/img/5147640.png"} />
		</Helmet>
		<Components.Header />
		<Section color="--dark">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				{" "}Про нас{" "}
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdStars}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Суть G-Stream
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Наш шлях розпочався з бачення надати винятковий досвід гри в гольф, який виходить за рамки просто гри в цей вид спорту. Ми уявляли собі місце, де кожен візит буде схожий на релаксацію, де любов до гольфу пронизує кожен аспект нашого клубу.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdStars}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Історія клубу
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					У гольф-клубі G-Stream наша історія не просто про гольф - вона про створення притулку, де живе і дихає дух гри. Наш клуб перетворився з простої любові до гольфу на спільноту, де кожен фервей, бункер і грін має свою історію, яка чекає на розкриття.
				</Text>
			</Box>
			<Box
				margin="36px 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 36px 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdStars}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Наші цінності
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					- Доступність для всіх:
Ми вважаємо, що гольф повинен бути доступним для всіх. Ця ідея відображена в наших інклюзивних варіантах членства, тренерських програмах та послугах з прокату обладнання.
					<br />
					<br />
					- Сталий розвиток:
Наша відданість принципам сталого розвитку проявляється в екологічно свідомому обслуговуванні полів, що забезпечує гармонійне поєднання з природою.
					<br />
					<br />
					- Пристрасть до гольфу:
Любов нашої команди до гри спонукає нас надавати неперевершений досвід гри в гольф, незалежно від того, чи є ви досвідченим гравцем, чи тільки починаєте.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Зв'яжіться з нами
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Дізнайтеся більше про те, що робить G-Stream унікальним місцем для любителів гольфу
				</Text>
				<Link
					href="/contact"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-green"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-green"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://wilonfisplad.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});